<template>
    <ul class="password-validation__rules">
        <li>
            <check
                v-if="password.length >= 8"
                width="18"
            />
            <alert-circle-outline
                v-else
                width="18"
            />
            Must be at least 8 characters.
        </li>
        <li>
            <check
                v-if="password.length <= 20"
                width="18"
            />
            <alert-circle-outline
                v-else
                width="18"
            />
            Must be less than 20 characters.
        </li>
        <li>
            <check
                v-if="lowerCasePatternTest(password)"
                width="18"
            />
            <alert-circle-outline
                v-else
                width="18"
            />
            Must be at least one lowercase character.
        </li>
        <li>
            <check
                v-if="upperCasePatternTest(password)"
                width="18"
            />
            <alert-circle-outline
                v-else
                width="18"
            />
            Must be at least one uppercase character.
        </li>
        <li>
            <check
                v-if="numberPatternTest(password)"
                width="18"
            />
            <alert-circle-outline
                v-else
                width="18"
            />
            Must be at least one number.
        </li>
    </ul>
</template>

<script>
import AlertCircleOutline from 'mdi-vue/AlertCircleOutline.vue'
import Check from 'mdi-vue/Check.vue'

export default {
    components: {
        AlertCircleOutline,
        Check
    },
    props: {
        password: {
            type: String,
            required: true
        }
    },
    methods: {
        lowerCasePatternTest(value) {
        // At least one LOWERCASE character:
            const lowerCasePattern = /^(?=.*[a-z]).+$/;
            return lowerCasePattern.test(value)
        },
        upperCasePatternTest(value) {
        // At least one LOWERCASE character:
            const upperCasePattern = /^(?=.*[A-Z]).+$/;
            return upperCasePattern.test(value)
        },
        numberPatternTest(value) {
        // At least one LOWERCASE character:
            const numberPattern = /^(?=.*[\d]).+$/;
            return numberPattern.test(value)
        }
    }
}
</script>
