import {
    required,
    minLength,
    maxLength
} from 'vuelidate/lib/validators';

export default {
    required,
    minLength: minLength(8),
    maxLength: maxLength(20),
    lowerCasePatternTest(value) {
        // At least one LOWERCASE character:
        const lowerCasePattern = /^(?=.*[a-z]).+$/;
        return lowerCasePattern.test(value)
    },
    upperCasePatternTest(value) {
        // At least one LOWERCASE character:
        const upperCasePattern = /^(?=.*[A-Z]).+$/;
        return upperCasePattern.test(value)
    },
    numberPatternTest(value) {
        // At least one LOWERCASE character:
        const numberPattern = /^(?=.*[\d]).+$/;
        return numberPattern.test(value)
    },
    spacesNotAllowed(value) {
        if (!value) {
            return false
        }
        return value.indexOf(' ') === -1
    }
}
